import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
  GroupWork} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team2021 = ({isDark}) => {
    const coreArr = [
        {
          img: "adrain.jpg",
          name: "Adrian Dsouza",
          position: " President"
        },
       
        {
          img: "rohit.jpeg",
          name: "Rohit Singh",
          position: "Vise President"
        },
        {
          img: "rudra.jpg",
          name: "Rudra Tiwari",
          position: "General secretary"
        },
        {
          img: "rajvi.jpg",
          name: "Rajvi Chokshi",
          position: "General secretary "
        },
        {
          img: "akansha.jpg",
          name: "C A Aakansha ",
          position: "Content and Editorial Secretary   "
        },
        {
          img: "pranali.jpg",
          name: "Pranali Rajendrakumar Khuspe ",
          position: "Content and Editorial - 2      "
        },
        {
          img: "ujjwal.png",
          name: "Ujjwal dixit",
          position: "Technical secretary"
        },
        
        {
          img: "kshitij.jpg",
          name: "Kshitij Jatekar",
          position: "Web Master"
        },
        {
          img: "ketaki.jpg",
          name: "Ketaki Dhotre",
          position: "Event Management Secretary "
        },
        {
          img: "flecture.jpeg",
          name: "Fletcher Fernandes",
          position: "Digital creative secretary -1"
        },
        {
          img: "shrusti.jpg",
          name: "Shrusti Warekar",
          position: "Digital creative secretary    "
        },
        {
          img: "harsheet.jpg",
          name: "Harsheet Soni      ",
          position: "Marketing-1"
        },
        {
          img: "aniket.jpg",
          name: "Aniket Solanki",
          position: "Marketing -2"
        },
        {
          img: "rucha.jpg",
          name: "Rucha Chotalia   ",
          position: "Publicity Secretary       "
        },
        {
          img: "abhay.jpeg",
          name: "Abhay Vekhande",
          position: "Treasurer"
        },
        {
          img: "ayush.jpg",
          name: "Ayush Dubey",
          position: "Dig Mark sec "
        },
    
  ];

  return (
    <div>
      <Container className="mt-9">
        <Box className="team-sections">
          <Typography
            variant="h3"
            align="center"
            component="p"
            className="dsc-font"
            data-aos="flip-left"
          >
            <span>
              Team 2021-2022 <GroupWork className="color-primary" fontSize="large" />
            </span>
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            {coreArr.map(val => (
              <Grid>
                <Member img={val.img} name={val.name} position={val.position} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Section4 isDark={isDark}/>
      </Container>
    </div>
  );
};

export default withRouter(Team2021);

