import React from 'react'

import { Typography } from "@material-ui/core";
import { Grid } from '@material-ui/core';
import TurningData from './TurningData';


const Turning = ({ isDark }) => {
  return (
    <><br/><br/><br/><br/><br/>
    <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
    >

        {
            TurningData.map((tr,key)=>(
                <><Grid item xs={12} sm={6} md={4}>  
                <Typography
                    variant="h5"
                    component="p"
                    className="dsc-font"
                    align="center"
                >
                    <span className="color-success bg-success">{tr.turningTitle}</span>
                </Typography>
                <center>
                    <a href={`/#/Turingview/${tr.turningLink}`} target="_blank" rel='noopener noreferrer'>  
                        <img src={tr.turningImage} alt={tr.turningTitle} height="250px" width="200px"/>
                    </a>
                </center>
            </Grid>
            
                </>
            ))
        }
      
    </Grid>
    </>
  )

    
  
}

export default Turning