import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
  GroupWork} from "@material-ui/icons";

import Section4 from "../landing-page/Section4";

const Team2019 = ({isDark}) => {
  const coreArr = [
    {
      img: "23.jpg",
      name: "Saad Ghojaria   ",
      position: " President"
    },
   
    {
      img: "24.jpg",
      name: "Mohit Sawant & Deepali Zutshi",
      position: "Vise President"
    },
    {
      img: "25.jpg",
      name: "Disha Shirdhankar",
      position: "General secretary"
    },
    
    {
      img: "26.jpg",
      name: "Giriraj Belanekar      ",
      position: "Treasurer"
    },
    {
      img: "27.jpg",
      name: "Deepanshu Sonparote & Bhaven Gore",
      position: "Asst. General secretary "
    },
    
    {
      img: "28.jpg",
      name: "Yash, Abhishek, Siddharth & Gopesh",
      position: "Event Management Secretaries "
    },
    
    {
      img: "29.jpg",
      name: "Sagar Ambilpure & Aayush Singh",
      position: "Technical secretaries"
    },
    
    {
      img: "30.jpg",
      name: "Suraj Kakad & Darshan Patel      ",
      position: "DS secretary and Web Master"
    },
    {
      img: "31.jpg",
      name: "Srushti Mande",
      position: "sports secretary"
    },
    
    {
      img: "32.jpg",
      name: "Akshi, Nitish, Sonal & Pranati      ",
      position: "Content and Editorial Secretaries   "
    },
    
    {
      img: "33.jpg",
      name: "Shivani, Ruchi & Disha",
      position: " creative and Marketing secretary "
    },
    {
      img: "34.jpg",
      name: " ",
      position: "Joint Secretaries      "
    },
    
  ];
  
  return (
    <div>
      <Container className="mt-9">
        <Box className="team-sections">
          <Typography
            variant="h3"
            align="center"
            component="p"
            className="dsc-font"
            data-aos="flip-left"
          >
            <span>
              Team 2019-2020 <GroupWork className="color-primary" fontSize="large" />
            </span>
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            {coreArr.map(val => (
              <Grid>
                <Member img={val.img} name={val.name} position={val.position} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Section4 isDark={isDark}/>
      </Container>
    </div>
  );
};

export default withRouter(Team2019);

