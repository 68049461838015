import React from "react";
import { Typography, Grid } from "@material-ui/core";
import CountUp from "react-countup";
import findings from "../../images/findings.jpg";
const Preface = () => {
  return (
    
      <Grid container>
        <Grid item md={6} sm={12}>
          <Typography variant="h3" component="p" className="dsc-font">
          THE FACTS SPEAK FOR THEMESELVES
          </Typography>

          <Typography variant="h4" component="p" className="dsc-font">
            <CountUp className="color-primary" end={250} duration={10} />
            <span>+ Total Strength</span>
          </Typography>

          <Typography variant="h4" component="p" className="dsc-font">
            <CountUp className="color-primary" end={36} duration={10} />
            <span>+ Total newslette published</span>
          </Typography>

          <Typography variant="h4" component="p" className="dsc-font">
            <CountUp className="color-success" end={13} duration={10} />
            <span>+ events done in 2019</span>
          </Typography>

         
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <img
            data-aos="fade-top"
            style={{height:"inherit",width:"100%"}}
            src={findings}
            alt="searching"
          ></img>
        </Grid>
      </Grid>
    
  );
};

export default Preface;
