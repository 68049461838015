import React from "react";
import { withRouter } from "react-router-dom";

function Verify() {
  return (
    <div>
      hrGposBd8vxeSEPLLpxpnuFrolUKWai9z60E4hjywS0.XKHI7OLJtdzIeru2F10WjJmz0K6VGCX64iAkJuEkpW8
    </div>
  );
}

export default withRouter(Verify);
