import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
  GroupWork} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team2017 = ({isDark}) => {
  const coreArr = [
    {
      
      img: "shoumik.jpg",
      name: "Shoumik Majumdar",
      position: " President"
    },
   
    {
      img: "nidihi.jpg",
      name: "Nidhi Vora",
      position: "General secretary"
    },
    {
      img: "akanksha.jpg",
      name: "Akanksha Chaudhari",
      position: "Digital Creative Secretary"
    },
    {
      img: "samina.jpg",
      name: "Samina Boxwala",
      position: "Content Secretary "
    },
    {
      img: "seher.jpg",
      name: "Seher Khan ",
      position: "Web master   "
    },
    {
      img: "yash.jpg",
      name: "Yash Badani ",
      position: "Technical Secretary   "
    },
    {
      img: "anindya.jpg",
      name: "Anindya Vinayak      ",
      position: "Editorial Secretary      "
    },
    
    {
      img: "munsih.jpg",
      name: "Munish Khurana ",
      position: "Chief Editor "
    },
    {
      img: "parinita.jpg",
      name: "Parinita Badre ",
      position: "Publicity Secretary    "
    },
    {
      img: "rushabh.jpg",
      name: "Rushabh Nisher      ",
      position: "Content Secretary "
    },
    {
      img: "muhammad.jpg",
      name: "Mohammad Madhia  ",
      position: "Assistant General Secretary   "
    },
    
  ];

  return (
    <div>
      <Container className="mt-9">
        <Box className="team-sections">
          <Typography
            variant="h3"
            align="center"
            component="p"
            className="dsc-font"
            data-aos="flip-left"
          >
            <span>
              Team 2017-2018 <GroupWork className="color-primary" fontSize="large" />
            </span>
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            {coreArr.map(val => (
              <Grid>
                <Member img={val.img} name={val.name} position={val.position} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Section4 isDark={isDark}/>
      </Container>
    </div>
  );
};

export default withRouter(Team2017);

