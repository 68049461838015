import React from "react";
import webdev1 from "../../images/wordpress.jpeg";
import webdev2 from "../../images/deep.jpeg";
import webdev3 from "../../images/django.jpg";

import { Typography, Box, Container } from "@material-ui/core";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@material-ui/icons/Work";
import { withRouter } from "react-router-dom";
import Section4 from "../landing-page/Section4";
const Timeline = ({isDark}) => {
  return (
    <div>

      <Box className="mt-9">

         <Typography
          className="dsc-font"
          component="p"
          variant="h3"
          align="center"
        >
          REACH-TEACH
        </Typography>

        <VerticalTimeline animate={false}>
         <VerticalTimelineElement
            contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
            Ecommerce Web development using wordpress</Typography>
            <img src={webdev1} height="75%" width="100%" alt="workshop-1" />
            <Typography component="p" variant="body1" align="left">
            WordPress (WP, WordPress.org) is a free and open-source content management system (CMS) written in PHP and paired with a MySQL or MariaDB database. And it provides a lot of features that makes creating website easy.

So here, we will be doing an E-commerce web development using wordpress.

What will you get to learn?🤔
1. Local host server setup
2. Installing wordpress and overview
3. Installing themes
4. Front-end Design
5. Ecommerce setup
6. Backend setup
7. Blogs and pages
8. Deployment and forward.</Typography>
          </VerticalTimelineElement>
       <VerticalTimelineElement
            contentStyle={{ background: "rgb(219,68,55)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(219,68,55)" }}
            date="2019"
            iconStyle={{ background: "rgb(219,68,55)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
             Introduction to Deep Learning
            </Typography>
            <img src={webdev2} height="75%" width="100%" alt="workshop-2" />
            <Typography component="p" variant="body1" align="left">
            Codecell in collaboration with  CESS , under the  Reach and Teach  initiative, brings to you a workshop on deep learning.

What is deep learning? 

Deep learning is a machine learning technique that teaches computers to do what comes naturally to humans: learn by example. The computer models learn to classify directly from the selected media.

What you will learn:

Through the workshop you’ll learn how to create and apply different types of  deep learning models  with a  working example project.
            </Typography>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: "rgb(15,157,88)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(15,157,88)" }}
            date="2019"
            iconStyle={{ background: "rgb(15,157,88)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
              Django Workshop
            </Typography>
            <img src={webdev3} height="75%" width="100%" alt="workshop-3" />
            <Typography component="p" variant="body1" align="left">
            CESS organised a workshop on Django, under the Reach and Teach program. The workshop helped students in understanding the basics of Django and its importance.

The workshop was conducted from 9:30 a.m. to  15:00 pm. on Monday in the Seminar Hall. There were more than 40 students that participated in this session. Omkar Chorghe, from RGIT Code Cell, and Sagar Ambilpure, conducted the workshop.

The workshop commenced with the speaker talking about Django and its utility in the real world. After the brief introduction, they helped the students create a virtual environment and proceeded to show various implementations of it.

The workshop helped the students understand the reason behind Django being preferred for making a web application.

After the session had ended, the students played two quizzes on ‘Kahoot!’, to assess how much they learnt through the workshop.
            </Typography>
          </VerticalTimelineElement>
       {/*  <VerticalTimelineElement
            contentStyle={{ background: "rgb(244,160,0)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(244,160,0)" }}
            date="2019"
            iconStyle={{ background: "rgb(244,160,0)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
              WebDev 19.5
            </Typography>
            <img src={webdev4} height="75%" width="100%" alt="workshop-4" />
            <Typography component="p" variant="body1" align="left">
              A workshop on Javascript Basics was conducted. Following a brief
              introduction about the language, the basics was taught to the
              students with the help of a live session.
            </Typography>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: "rgb(15,157,88)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(15,157,88)" }}
            date="2019"
            iconStyle={{ background: "rgb(15,157,88)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
              WebDev 19.6
            </Typography>
            <img src={webdev5} height="75%" width="100%" alt="workshop-5" />
            <Typography component="p" variant="body1" align="left">
              A workshop on DOM Manipulation was conducted , the workshop was
              fortunate to have zestful engagement from students. The leading
              technical facilitators looked after the proper functioning and
              ensured that workshop led a systematised and methodical way.
            </Typography>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: "rgb(244,160,0)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(244,160,0)" }}
            date="2019"
            iconStyle={{ background: "rgb(244,160,0)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
              UI/UX Session
            </Typography>
            <img src={webdev6} height="75%" width="100%" alt="workshop-6" />
            <Typography component="p" variant="body1" align="left">
              A UI/UX session was conducted to familiarize attendees with the
              basics of designing in association with Prismatics
            </Typography>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: "rgb(219,68,55)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(219,68,55)" }}
            date="2019"
            iconStyle={{ background: "rgb(219,68,55)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              className="vertical-timeline-element-title"
              component="p"
              variant="h3"
              align="left"
            >
              Python Basics Workshop
            </Typography>
            <img src={python} height="75%" width="100%" alt="workshop-7" />
            <Typography component="p" variant="body1" align="left">
              A workshop on Basic Python was conducted , the workshop was
              fortunate to have active participation from students of all
              batches. The main technical coordinators looked after the smooth
              running of and ensured that workshop followed an automated
              process.
            </Typography>
          </VerticalTimelineElement>
         </VerticalTimeline>
              </Box>
              <Container id="contact">
        <Section4 isDark={isDark}/>
 */}    </VerticalTimeline>  </Box>
    
    <Container id="contact">
        <Section4 isDark={isDark}/>
        
      </Container>
      
    </div>
  );
};

export default withRouter(Timeline);
