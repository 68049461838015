import React,{useState} from "react";
import DialogContent from "@material-ui/core/DialogContent";
import dscjiit from '../../images/logo-ces.png';
import dscjiitDark from '../../images/logo-ces.png';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import da from "../../images/4.jpeg";
import an from "../../images/anik.jpg";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import yo from "../../images/yog.jpeg"
const Section4 = ({isDark}) => {
  const [s1,sets1]=useState(false);
  const [s2,sets2]=useState(false);
  const [s3,sets3]=useState(false);
  return (
    <div>
      <Box mt={3} mb={2} className="section-4">
     
        
        <Box mt={5}>
          <Typography
            variant="body1"
            component="p"
            className="dsc-font"
            align="center"
          >
          Created By <a href="/" onMouseOver={() => sets1(true)}  >Yogita Chaudhari </a>
          and <a href="/" onMouseOver={() => sets2(true)} >Aniket Chauhan</a>
          <br/> Under guidence of<a href="/" onMouseOver={() => sets3(true)} > Prof.Dilip Dalgade</a>
          </Typography>
          <img 
          src={isDark?dscjiitDark:dscjiit} 
          alt="logo" />
        </Box>
      </Box>
      <Dialog open={s1} onClose={()=>sets1(false)} height="300px" width="250px">
      <DialogTitle>{<a href="https://www.linkedin.com/in/yogita01/">Yogita Chaudhari </a>}</DialogTitle>
       <DialogContent> 
        <img 
          src={yo} 
          alt="yogita" height="300px" width="250px" />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>sets1(false)} 
                  color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={s2} onClose={()=>sets2(false)} height="300px" width="250px">
      <DialogTitle>{ <a href="https://www.linkedin.com/in/aniket-chauhan-2baa9a1aa/">Aniket Chauhan</a>}</DialogTitle>
      <DialogContent> 
        <img 
          src={an} 
          alt="aniket" height="300px" width="250px"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>sets2(false)} 
                  color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={s3} onClose={()=>sets3(false)} height="300px" width="250px">
      <DialogTitle>{<a href="https://www.linkedin.com/in/dilip-dalgade-6b007823/">Prof. Dilip dalgade</a>}</DialogTitle>
      <DialogContent> 
        <img 
          src={da} 
          alt="dalgade sir" height="300px" width="250px" />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>sets3(false)} 
                  color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Section4;

