import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
  GroupWork} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team2018 = ({isDark}) => {
  const coreArr = [
    {
      img: "35.jpeg",
      name: "Pratik Rao      ",
      position: " President"
    },
   
    {
      img: "36.jpeg",
      name: "Ankita Datta      ",
      position: "General secretary"
    },
    {
      img: "37.jpeg",
      name: "Shubham Mishra      ",
      position: "General secretary "
    },
    {
      img: "38.jpeg",
      name: "Gaurav",
      position: "Treasurer"
    },
    {
      img: "39.jpeg",
      name: "Aniket",
      position: "Event Management Secretary "
    },
    
    {
      img: "40.jpeg",
      name: "Tapish Sharma      ",
      position: "Event Management Secretary "
    },
    {
      img: "41.jpg",
      name: "Sameer",
      position: "Technical secretary"
    },
    {
      img: "42.jpg",
      name: "Saad Ghojaria      ",
      position: "Web Master"
    },
    {
      img: "43.jpeg",
      name: "Neha Parab      ",
      position: "Assistant General Secretary      "
    },
    {
      img: "44.jpg",
      name: "Mandar ",
      position: "Content and Editorial Secretary   "
    },
    
  ];
  
  return (
    <div>
      <Container className="mt-9">
        <Box className="team-sections">
          <Typography
            variant="h3"
            align="center"
            component="p"
            className="dsc-font"
            data-aos="flip-left"
          >
            <span>
              Team 2018-2019 <GroupWork className="color-primary" fontSize="large" />
            </span>
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            {coreArr.map(val => (
              <Grid>
                <Member img={val.img} name={val.name} position={val.position} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Section4 isDark={isDark}/>
      </Container>
    </div>
  );
};

export default withRouter(Team2018);

