import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import gcp1 from "../../images/2.jpg";

const tutorialSteps = {
  gcp: [
    {
      label: "Prof. Bhushan M Patil",
      imgPath: gcp1,
      position:"Student's convener"
    },
  ],
 
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,marginLeft:5,marginRight:5,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    
  },
  img: {
    overflow: "hidden",
    display: "block",
    width: "100%"
  }
}));

export default function TextMobileStepper(props) {
  const eventName = props.name;
  const array = Object.entries(tutorialSteps);
  var images = [];
  array.forEach(([key, value]) => {
    if (key === eventName) {
      images = value;
    }
  });
  const classes = useStyles();
  const [activeStep] = React.useState(0);
 
 
  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={`${classes.header} highlight-heading`}>
        <Typography className="dsc-font">{images[activeStep].label} <br/>{images[activeStep].position}</Typography>
      </Paper><br/>
      <img
        id="carousel-img"
        className={classes.img}
        src={images[activeStep].imgPath}
        alt={images[activeStep].label}
        height="400px"
        width="680px"
      />
    </div>
  );
}
