import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
  GroupWork} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team2020 = ({isDark}) => {
  const coreArr = [
    {
      img: "1.jpg",
      
      name: "Abhishek Rathod ",
      position: " President"
    },
   
    {
      img: "2.jpg",
      name: "Sidhesh Toraskar    ",
      position: "Vise President"
    },
    {
      img: "3.jpg",
      name: "Shubham Rathod  ",
      position: "General secretary"
    },
    {
      img: "4.jpg",
      name: "Ruchi Isaac      ",
      position: "General secretary "
    },
    {
      img: "5.jpg",
      name: "Pratik Varandekar      ",
      position: "Treasurer"
    },
    {
      img: "6.jpg",
      name: "Sakshi More",
      position: "Asst.General secretary"
    },
    {
      img: "7.jpg",
      name: "Arshunnu Bare",
      position: "Asst.General secretary "
    },
    
    {
      img: "8.jpg",
      name: "Himanshu Rathod",
      position: "E.M Secretaries      "
    },
    {
      img: "9.jpg",
      name: "Aditya Arvind ",
      position: "E.M Secretaries      "
    },
    {
      img: "10.jpg",
      name: "Priyash Nagarkar ",
      position: "E.M Secretaries      "
    },
    {
      img: "11.jpg",
      name: "Aniket Rai      ",
      position: "Tech Master"
    },
    
    {
      img: "12.jpg",
      name: "Amit Nemade      ",
      position: "Web Master"
    },
    {
      img: "14.jpg",
      name: "Raheb Sheikh      ",
      position: "Marketing-1"
    },
    {
      img: "15.jpg",
      name: "Shivani Jadhav      ",
      position: " creative secretary -1"
    },
    {
      img: "16.jpg",
      name: "Bhagyesh Rane      ",
      position: " creative secretary    "
    },
    
    {
      img: "17.jpg",
      name: "Manali Shivapurkar      ",
      position: " Digital secretary -1"
    },
    {
      img: "18.jpg",
      name: "Devesh Pawar      ",
      position: " Digital secretary    "
    },
    {
      img: "19.jpg",
      name: "Advait K      ",
      position: "Content & Editorial   "
    },
    {
      img: "20.jpg",
      name: "Tejas Shenoy      ",
      position: "Marketing Secretary"
    },
    {
      img: "22.jpg",
      name: "Sarah Das",
      position: "Publicity Secretary       "
    },
    
  ];

  return (
    <div>
      <Container className="mt-9">
        <Box className="team-sections">
          <Typography
            variant="h3"
            align="center"
            component="p"
            className="dsc-font"
            data-aos="flip-left"
          >
            <span>
              Team 2020-2021 <GroupWork className="color-primary" fontSize="large" />
            </span>
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            {coreArr.map(val => (
              <Grid>
                <Member img={val.img} name={val.name} position={val.position} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Section4 isDark={isDark}/>
      </Container>
    </div>
  );
};

export default withRouter(Team2020);

