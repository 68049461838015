import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
    GroupWork
} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team2022 = ({ isDark }) => {

    const coreArr = [
        {
            img: "harsheet-22-23.jpg",
            name: "Harsheet Soni",
            position: " President"
        },
        {
            img: "fletcher-22-23.png",
            name: "Fletcher Fernandes",
            position: "Managing Director"
        },
        {
            img: "aniket-22-23.JPG",
            name: "Aniket Solanki",
            position: "Vise President"
        },
        {
            img: "bhagyasha-22-23.jpeg",
            name: "Bhagyasha Patil",
            position: "General secretary"
        },
        {
            img: "Sakshi-22-23.jpg",
            name: "Sakshi Pandey",
            position: "General secretary "
        },
        {
            img: "shlok-22-23.jpg",
            name: "Shlok Jagushte",
            position: "Assistant General secretary "
        },
        {
            img: "kunal-22-23.jpg",
            name: "Kunal Singh",
            position: "Treasurer"
        },
        {
            img: "Mihir-22-23.jpg",
            name: "Mihir Vartak",
            position: "Treasurer"
        },
        {
            img: "aniket-chauhan-22-23.jpg",
            name: "Aniket Chauhan",
            position: "Technical secretary"
        },
        {
            img: "ishita-22-23.jpg",
            name: "Ishita Borkar",
            position: "Marketing Secretary"
        },
        {
            img: "labeeb-22-23.jpg",
            name: "Labeeb Ansari",
            position: "Marketing Secretary"
        },
        {
            img: "yogita-22-23.jpg",
            name: "Yogita Chaudhari",
            position: "Web Master"
        },
        {
            img: "mamta-22-23.jpg",
            name: "Mamta Bandgar",
            position: "Content and Editorial Secretary   "
        },
        {
            img: "shanmukh-22-23.jpg",
            name: "Shanmukh Bhutada",
            position: "Content and Editorial Secretary   "
        },
        {
            img: "bhuvani-22-23-1.jpeg",
            name: "Bhuvani Pai",
            position: "Digital creative secretary    "
        },
        {
            img: "fardeen-22-23.jpeg",
            name: "Fardeen Shroff",
            position: "Event Management Secretary "
        },
        {
            img: "husain-22-23-1.jpg",
            name: "Husain Dahodwala",
            position: "Event Management Secretary "
        },
        {
            img: "disha-22-23.jpg",
            name: "Disha Dhako",
            position: "Publicity Secretary"
        },

    ];

    return (
        <div>
            <Container className="mt-9">
                <Box className="team-sections">
                    <Typography
                        variant="h3"
                        align="center"
                        component="p"
                        className="dsc-font"
                        data-aos="flip-left"
                    >
                        <span>
                            Team 2022-2023 <GroupWork className="color-primary" fontSize="large" />
                        </span>
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        {coreArr.map(val => (
                            <Grid>
                                <Member img={val.img} name={val.name} position={val.position} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Section4 isDark={isDark} />
            </Container>
        </div>
    );
};

export default withRouter(Team2022);



