import React from "react";
import { Typography, Container, Box, Grid, List } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Carousel from "../highlights/Carousel";
import Carousel1 from "../highlights/Carousel1"
import Carousel2 from "../highlights/Carousel2"
import Carousel3 from "../highlights/Carousel3"
import {
  GroupWork,

} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team = ({ isDark }) => {
  const coreArr = [
    {
      "name": "Santa Kumar Pulipaka",
      "img": "Santa_Kumar_Pulipaka.jpg",
      "position": "President"
    },
    {
      "name": "Rushikesh Shelar",
      "img": "Rushikesh shelar.jpg",
      "position": "Vice President"
    },
    {
      "name": "Mohammad Razeen Sayyed",
      "img": "Mohammad_Razeen_Sayyed.jpg",
      "position": "Managing Director"
    },
    {
      "name": "Riya Pradhan",
      "img": "Riya_Pradhan.jpg",
      "position": "Female Vice President"
    },
    {
      "name": "Pravin Rajnale",
      "img": "login.jpg",
      "position": "General Manager"
    },
    {
      "name": "Pranav Iskande",
      "img": "Pranav_Iskande.jpg",
      "position": "Lead"
    },
    {
      "name": "Amaan Ansari",
      "img": "Amaan_Ansari.jpg",
      "position": "Assistant Lead"
    },
    {
      "name": "Sejal Ghadge",
      "img": "Sejal_Ghadge.jpg",
      "position": "Assistant Lead"
    },
    {
      "name": "Ketan Chavan",
      "img": "Ketan_Chavan.jpg",
      "position": "General Secretary"
    },
    {
      "name": "Aishwarya Gupta",
      "img": "Aishwarya_Gupta.jpg",
      "position": "Joint General Secretary"
    },
    {
      "name": "Arya Patil",
      "img": "login.jpg",
      "position": "Joint General Secretary"
    },
    {
      "name": "Yashraj Gavale",
      "img": "Yashraj_Gavale.jpg",
      "position": "Joint General Secretary"
    },
    {
      "name": "Sakshi Shinde",
      "img": "Sakshi_Shinde.jpg",
      "position": "Joint General Secretary"
    },
    {
      "name": "Harshita Patil",
      "img": "Harshita_Patil.jpg",
      "position": "Content & Editorial Secretary"
    },
    {
      "name": "Nishika Mehta",
      "img": "NishikaMehta.jpg",
      "position": "Publicity Secretary"
    },
    {
      "name": "Tisha Tejwani",
      "img": "TishaTejwani.jpg",
      "position": "Marketing Secretary"
    },
    {
      "name": "Shubham Jankar",
      "img": "shubham.jpg",
      "position": "Event Management Secretary"
    },
    {
      "name": "Dhirendra Lohar",
      "img": "Dhruv.jpeg",
      "position": "Web Secretary"
    },
    {
      "name": "Gargi Hawaldar",
      "img": "Gargi_Hawaldar.jpg",
      "position": "Digital Creative Secretary"
    },
    {
      "name": "Dhruv Gohil",
      "img": "DHRUV.jpg",
      "position": "Technical A&V Secretary"
    },
    {
      "name": "Mohit Jain",
      "img": "Mohit_Jain.jpg",
      "position": "Social Media Secretary"
    },
  ]



  return (
    <div>
      <Box className='mt-9'>
        <Typography
          id="csj"
          className="dsc-font"
          component="p"
          variant="h3"
          align="center"
        >
          About Us
        </Typography>
        <Container>
          <Box mt={5}>
            <Grid container>
              <Grid item sm={12} md={6} lg={6} data-aos="flip-left">
                <Box>
                  <Container>
                    <Typography
                      className="dsc-font"
                      component="p"
                      variant="h4"
                      align="center"
                    >
                      RGIT Cess
                    </Typography>
                    <Typography component="p" variant="body1" align="justify">
                      Teaching in this institution for more than two decades has endowed me with many momentous memories throughout my term. The memory that shines the brightest out of all of them, belongs to the day the Computer Engineering Student’s Society (CESS) came into existence. The synchronization between the latest technology and the ones from the past play an important role in advancing throughout the years.
                      The institution has always encouraged hard work and honesty, and our students have always upheld these values. The students, across the years, have worked relentlessly on various projects and events and have always come up with new ideas that result in great outcomes.
                      <br />I strongly applaud the CESS committee on their marvelous work on Turing magazine, helpful workshops and events and assisting the department in its endeavour. The students always delivered their best and never cease to impress the institute with their creativity and consistency. There is no substitute for hard work, this is something I have always believed in and I always encourage our students to always do the best they can in any situation that presents itself.
                      I hope CESS continues to bloom and expand in the years to come.
                      <br></br>
                      <i> <b>Prof.SUNIL KHACHANE <br />HOD of Computer Engineering Department , Founder of RGIT CESS
                      </b></i>
                    </Typography>
                  </Container>
                </Box>
              </Grid><br /><br /><br />
              <Grid item sm={12} md={6} lg={6} className="carousel">
                <Carousel data-aos="flip-right" name="gcp" />
              </Grid>
            </Grid>
            <Container>
              <Typography
                className="dsc-font"
                component="p"
                variant="h3"
                align="left"
              ><br /><br />
                <center> The Core</center>
              </Typography>
              <Grid container>
                <Grid item sm={12} md={6} lg={6} className="carousel" >
                  <Carousel1 data-aos="flip-left" name="gcp" />
                </Grid>
                <Grid item sm={12} md={6} lg={6} className="carousel" >
                  <Carousel2 data-aos="flip-right" name="gcp" />
                </Grid>
                {/* <Grid item sm={12} md={6} lg={4} className="carousel" >
                  <Carousel3 data-aos="flip-center" name="gcp" />
                </Grid> */}
              </Grid>
            </Container>
          </Box>
        </Container>


        <Box className="team-sections" style={{marginTop: "4rem"}}>
          <Typography
            variant="h3"
            align="center"
            component="p"
            className="dsc-font"
            data-aos="flip-left"
          >
            <span>
              TEAM 2024-2025 <GroupWork className="color-primary" fontSize="large" />
            </span>
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            {coreArr.map(val => (
              <Grid>
                <Member img={`2024/${val.img}`} name={val.name} position={val.position} />
              </Grid>
            ))}
          </Grid>
        </Box>



        <List>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2023";
            }}
          >

            {/* Added 2023-2024 Accordian  */}
             <ListItemText primary={"Team 2023-2024"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2022";
            }}
          >
            <ListItemText primary={"Team 2022-2023"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2021";
            }}
          >
            <ListItemText primary={"Team 2021-2022"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2020";
            }}
          >
            <ListItemText primary={"Team 2020-2021"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2019";
            }}
          >
            <ListItemText primary={"Team 2019-2020"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2018";
            }}
          >
            <ListItemText primary={"Team 2018-2019"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location = "#/team2017";
            }}
          >
            <ListItemText primary={"Team 2017-2018"} />
          </ListItem>

        </List>

        <Section4 isDark={isDark} />
      </Box>
    </div>
  );

};

export default withRouter(Team);

