import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import cosmic from '../../images/g2.gif';
import dscjiit from '../../images/cess1.png';
import dscjiitDark from '../../images/cess1.png';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import csilogo from "../../images/csi-logo1.png"

const Section1 = ({ isDark }) => {
  
  return (
    <div >
      <Box className='mt-3'>
      <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
            
        <Grid container >

          <Grid item sm={12} md={6} lg={6}>
          <Box mt={8}><center>    <br/>
          <br/>    <br/>   
            
            <img
                className='first-image'
                src={isDark ? dscjiitDark : dscjiit}
                alt='dscjiitlogo'
                height="85px"></img>
                <img
              src={isDark?csilogo:csilogo}
              alt="searching"
              className="csi-image"
              height="85px"
            ></img>
             
              </center>

              <Box mt={2}>
                <Container>
                  <Typography component='p' variant='body1' align='left'>
                   RGIT- Computer Engineering Student’s Society (CESS) are basically a community with connections all over
                    the world. Open to any student, ranging from novice
                    developers who are just starting, to advanced developers who
                    want to further enhance their skills, we provide a platform
                    for students to learn and collaborate in solving problems
                    around them with the help of technology.
                  </Typography>
                </Container>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={6} data-aos='fade-bottom'>
          <img
              data-aos='fade-top'
              className='first-gif'
              src={cosmic}
              alt='searching' />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Section1;
