import React from "react";
import Section4 from "../landing-page/Section4";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import S1 from "./S1";

const Contact = ({isDark}) => {
  return (<>
    
        <div>     
        <Container >
        <S1 isDark={isDark}/>
      </Container>
      
      <Container id="contact">
        <Section4 isDark={isDark}/>
        
      </Container>
      
    </div></>
  );
};

export default withRouter(Contact);
