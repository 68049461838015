import React,{useEffect,useState} from 'react'

import { Typography } from "@material-ui/core";
import { Grid } from '@material-ui/core';
import TurningData from '../Components/Turning/TurningData';
import trsep22 from './sep2022.pdf';
import trmar22 from './mar22.pdf';
import trjan22 from './jan22.pdf';
import traug21 from './aug21.pdf';
import trsep20 from './sep20.pdf';
import traug20 from './aug20.pdf';
import trfeb20 from './feb20.pdf';
import trjan20 from './jan20.pdf';
import tr2019 from './2019.pdf';
import tr2018 from './2018.pdf';
import feb23 from './feb23.pdf';
import june23 from './june23.pdf';


const TurningView = ({ isDark }) => {
    const [prd,setprd]=useState('');
   const [file1,setfile]=useState();
   
   useEffect(()=>{
    let pe=window.location.href.split('/')[5]
    if (pe === 'june23') {
      setprd(june23);
      console.log("hello")
      return ;
    }
    setprd(pe);
    setfile(`./${pe}.pdf`);
    console.log(file1);
   })
  return (
    <><div >
        <br/><br/><br/><br/><br/>
        <center style={{height:'100%',minHeight:'80%'}} > 
        <iframe src={prd !== june23 ? `${prd}.pdf` : prd} height={`${window.innerHeight}`} width="80%" />
        </center>
            </div>
    </>
  )

    
  
}

export default TurningView