import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Member from "./MemberCard";
import {
    GroupWork
} from "@material-ui/icons";
import Section4 from "../landing-page/Section4";

const Team2023 = ({ isDark }) => {

    const coreArr = [
    {
      "name": "Anushka Matey",
      "img": "Anushka_Matey.jpg",
      "position": "President"
    },
    {
      "name": "Chirag Kocharekar",
      "img": "Chirag_Kocharekar_1_(VP).jpg",
      "position": "Vice President"
    },
    {
      "name": "Rohan Rathod",
      "img": "rohan_cess.jpg",
      "position": "Managing Director"
    },
    {
      "name": "Amey Godbole",
      "img": "Amey_(GS).jpg",
      "position": "General Secretary"
    },
    {
      "name": "Tushhar Barkur",
      "img": "Tushhar Barkur 1 (GS).jpg",
      "position": "General Secretary"
    },
    {
      "name": "Hamza Shaikh",
      "img": "Hamza_Shaikh_2_(Treasurer).jpg",
      "position": "Treasurer"
    },
    {
      "name": "Tejas Pawar",
      "img": "Tejas Pawar 1 (Technical Secretary).JPG.JPG",
      "position": "Technical Secretary"
    },
    {
      "name": "Aaditi Mali",
      "img": "Aaditi_Mali_2_(C&E).jpg",
      "position": "Content and Editorial Secretary"
    },
    {
      "name": "Rushikesh Shelar",
      "img": "Rushikesh Shelar (C&E).jpg",
      "position": "Content and Editorial Secretary"
    },
    {
      "name": "Sakshi Tambe",
      "img": "Sakshi_Tambe.jpg",
      "position": "Content and Editorial Secretary"
    },
    {
      "name": "Aniket Singh",
      "img": "Aniket_Singh_(Marketing_Secretary).jpg",
      "position": "Marketing Secretary"
    },
    {
      "name": "Nandini Yadav",
      "img": "Nandini Yadav.jpg",
      "position": "Asst. Marketing Secretary"
    },
    {
      "name": "Aditi Tatkare",
      "img": "Aditi_Tatkare_DC_Secretary.jpg",
      "position": "Digital Creative Secretary"
    },
    {
      "name": "Sejal Ghadge",
      "img": "Sejal Ghadge .jpg",
      "position": "Asst. Digital Creative Secretary"
    },
    {
      "name": "Krish Ramle",
      "img": "Krish Ramle 2 (Asst DC St).jpg",
      "position": "Asst. Digital Creative Secretary"
    },
    {
      "name": "Kishan Kathare",
      "img": "Kishan_Kathare_1(Web master).jpg",
      "position": "Web master"
    },
    {
      "name": "Krishay Nair",
      "img": "Krishay Nair 2.jpg",
      "position": "Asst. Web Master"
    },
    {
      "name": "Shreyash Jadhav",
      "img": "Shreyash Jadhav EM secretary .JPG",
      "position": "Event Management secretary"
    },
    {
      "name": "Kartik Mistry",
      "img": "Kartik Mistry 1 (Asst. Event Management Secretary).jpg",
      "position": "Asst. Event Management Secretary"
    },
    {
      "name": "Razeen Sayyed",
      "img": "Raz2.jpg",
      "position": "Asst. Event Management secretary"
    },
    {
      "name": "Srushti Patyane",
      "img": "Srushti P 2 (Publicity).jpg",
      "position": "Publicity Secretary"
    },
    {
      "name": "Riya Pradhan",
      "img": "Riya Pradhan 1.jpg",
      "position": "Asst. Publicity Secretary"
    },
  ]

    return (
        <div>
            <Container className="mt-9">
                <Box className="team-sections">
                    <Typography
                        variant="h3"
                        align="center"
                        component="p"
                        className="dsc-font"
                        data-aos="flip-left"
                    >
                        <span>
                            Team 2023-2024 <GroupWork className="color-primary" fontSize="large" />
                        </span>
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        {coreArr.map(val => (
                            <Grid>
                                <Member img={`2023/${val.img}`} name={val.name} position={val.position} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Section4 isDark={isDark} />
            </Container>
        </div>
    );
};

export default withRouter(Team2023);



