import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import Home from "../Components/landing-page/Home";
import Timeline from "../Components/highlights/Timeline";
import Team from "../Components/team/Team";
import Team2017 from "../Components/team/Team2017";
import Team2018 from "../Components/team/Team2018";
import Team2019 from "../Components/team/Team2019";
import Team2020 from "../Components/team/Team2020";
import Team2021 from "../Components/team/Team2021";
import Team2022 from "../Components/team/Team2022";
import Team2023 from "../Components/team/Team2023";

import Verify from "../Components/verification/Verify";
import Navbar from "../Components/common/Navbar";
import EventPage from "../Components/EventsPage/Events";
import Turning from '../Components/Turning/Turning';
import Photobooth from "../Components/bitbox/Photobooth";

import Contact from "../Components/contact/Contact";
import TurningView from "../turing/TurningView";
class Router extends Component {
  constructor(props){
    super();
  }
  
  render() {
    return (
      <HashRouter basename="/">
        <div>
          <Navbar turnDark={this.props.turnDark} isDark={this.props.isDark}/>
          <Switch>
            <Route path="/" render={()=><Home isDark={this.props.isDark}/>} exact />
            <Route path="/highlights" render={()=><Timeline isDark={this.props.isDark}/>} exact />
            <Route path="/Team" render={()=><Team isDark={this.props.isDark}/>} exact />
            <Route path="/contacts" render={()=><Contact isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2017" render={()=> <Team2017 isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2018" render={()=> <Team2018 isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2019" render={()=> <Team2019 isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2020" render={()=> <Team2020 isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2021" render={()=> <Team2021 isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2022" render={()=> <Team2022 isDark={this.props.isDark}/>} exact />
            <Route  path="/Team2023" render={()=> <Team2023 isDark={this.props.isDark}/>} exact /> 
            <Route  path="/Turing" render={()=> <Turning isDark={this.props.isDark}/>} exact />
            <Route path="/Turingview/:id" render={()=> <TurningView isDark={this.props.isDark}/>  }  />
            <Route path="/events" render={() => <EventPage isDark={this.props.isDark}/>} exact />
            <Route
              path="/.well-known/acme-challenge/hrGposBd8vxeSEPLLpxpnuFrolUKWai9z60E4hjywS0"
              component={Verify}
              exact
            />
            <Route path="/*" render={()=><Home isDark={this.props.isDark}/>} exact />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default Router;
