import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import fbIcon from "../../images/Facebook.svg";
import googleIcon from "../../images/google-plus.svg";
import linkedin from "../../images/linkedin.svg";
import instagramIcon from "../../images/Instagram.svg";
import Carousel from "react-multi-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import insta1 from "../../images/insta1.mp4";
import insta2 from "../../images/insta2.jpeg";
import insta3 from "../../images/insta3.jpeg";
import insta4 from "../../images/codertine.jpg"
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const S1 = ({ isDark }) => {
  
  return (
    <div >
      <Box className='mt-9'>
      <Typography component='h1' variant='h4' align='center'>Contact Us
                  </Typography>
        <Grid container >

          <Grid item sm={12} md={6} lg={6}>
          <Box mt={8}>
                <Container>
                  <Typography component='p' variant='body1' align='left'>
                  MANJARA CHARITABLE TRUST'S <br/>
                  RAJIV GANDHI INSTITUTE OF TECHNOLOGY <br/>
                  Juhu Versova Link Road,Behind HDFC BANK VERSOVA,<br/>
                  Andheri(West),Mumbai - 400 053
                  </Typography><br/>
                </Container>
            </Box>
            
          </Grid>
          <Grid item sm={12} md={6} lg={6} data-aos='fade-bottom'>
          <div className="mapouter">
        <div className="gmap_canvas">
          <iframe 
          title="map"
          id="gmap_canvas"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.6890511949305!2d72.82152561485196!3d19.12129248706189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9e13ef12003%3A0x5767a74a751ccaf9!2sRajiv%20Gandhi%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1639930057114!5m2!1sen!2sin" 
          width="100%" height="340" 
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          >
          </iframe>
          </div>
        </div>

          </Grid>
        </Grid>

        <br/>
        <Container>
       <Typography
          variant="h3"
          component="p"
          className="dsc-font"
          align="center"
        >
           <span className="color-primary">Follow us on Instagram</span> 
        </Typography>
        <Carousel responsive={responsive}>
        <div>  <center><a href="https://www.instagram.com/p/CZYq06Rh1F0/" target='_blank' rel='noopener noreferrer'>  <video alt="insta1" src={insta1} height="250px" width="200px"/></a></center>
        </div>
        <div>  <center><a href="https://www.instagram.com/p/CZLmrn-jXvE/" target='_blank' rel='noopener noreferrer'>  <img alt="insta2" src={insta2} height="250px" width="200px"/></a></center>
        </div>
        <div><center><a href="https://www.instagram.com/p/CZWF2_YPC9F/" target='_blank' rel='noopener noreferrer'>  <img alt="insta3" src={insta3} height="250px" width="200px"/></a></center>
        </div>
        <div>
        <center><a href="https://www.instagram.com/p/CVsbaY3jLz4/" target='_blank' rel='noopener noreferrer'>  <img alt="insta4" src={insta4} height="250px" width="200px"/></a></center>
        </div>
        </Carousel>
       <center><a href="https://www.instagram.com/rgitcess/" target='_blank' rel='noopener noreferrer'>
         
       <Typography
                variant="h5"
                component="p"
                className="dsc-font"
                align="center"
              >
                <span className="color-success bg-success"> Follow on instagram</span>
              </Typography>
       </a>
      </center>
      </Container>
        <br/>
        <Typography
          variant="h4"
          component="p"
          className="dsc-font"
          align="center"
        >
          <span className="color-primary">Catch us on </span>
        </Typography>
        <Grid container justify="center">
          <Grid item data-aos="zoom-in" data-aos-duration="500">
            <a href="https://www.facebook.com/RgitCess">
              <img src={fbIcon} alt="fb-icon" className="social-icon" />
            </a>
          </Grid>
          <Grid item data-aos="zoom-in" data-aos-duration="1300">
            <a href="mailto:mctrgitcess@gmail.com">
              <img src={googleIcon} alt="google-icon" className="social-icon" />
            </a>
          </Grid>

          <Grid item data-aos="zoom-in" data-aos-duration="1700">
            <a href="https://www.linkedin.com/company/cess-computer-engineering-students-society">
              <img src={linkedin} alt="Linkedin-icon" className="social-icon" />
            </a>
          </Grid>

          <Grid item data-aos="zoom-in" data-aos-duration="2100">
            <a href="https://www.instagram.com/rgitcess">
              <img src={instagramIcon} alt="instagram-icon" className="social-icon" />
            </a>
          </Grid>
        </Grid>

      </Box>
    </div>
  );
};

export default S1;
