import trsep22 from '../../turing/sep2022.pdf';
import trmar22 from '../../turing/mar22.pdf';
import trjan22 from '../../turing/jan22.pdf';
import traug21 from '../../turing/aug21.pdf';
import trsep20 from '../../turing/sep20.pdf';
import traug20 from '../../turing/aug20.pdf';
import trfeb20 from '../../turing/feb20.pdf';
import trjan20 from '../../turing/jan20.pdf';
import tr2019 from '../../turing/2019.pdf';
import tr2018 from '../../turing/2018.pdf';
import trfeb23 from '../../turing/feb23.pdf';
import feb23 from '../../turing/feb23.jpeg';
import sep2022 from '../../turing/sep22.png';
import Mar2022 from "../../images/Mar2022.jpeg";
import Jan2022 from "../../images/Jan2022.jpeg";
import Aug2021 from "../../images/Aug2021.jpg";
import sept2020 from "../../images/sept2020.png";
import aug2020 from "../../images/aug2020.png";
import feb2020 from "../../images/feb2020.jpg";
import jan2020 from "../../images/jan2020.jpg";
import t2021 from "../../images/2021.jpeg";
import t2019 from "../../images/2019.png";
import t2018 from "../../images/2018.png";
import June23 from "../../images/June23.jpeg";


const TurningData = [
    {
        turningTitle: "June 2023",
        turningTitleClass: "color-primary bg-primary",
        turningLink: 'june23',
        turningImage: June23
    },
    {
        turningTitle: "February 2023",
        turningTitleClass: "color-success bg-success",
        turningLink: 'feb23',
        turningImage: feb23
    },
    {
        turningTitle: "September 2022",
        turningTitleClass: "color-success bg-success",
        turningLink: 'sep2022',
        turningImage: sep2022
    },
    
    {
        turningTitle: "March 2022",
        turningTitleClass: "color-success bg-success",
        turningLink:'mar22',
        turningImage: Mar2022
    },
    {
        turningTitle: "January 2022",
        turningTitleClass: "color-warning bg-warning",
        turningLink:'jan22' ,
        turningImage: Jan2022
    },
    {
        turningTitle: "August 2021",
        turningTitleClass: "color-primary bg-primary",
        turningLink: 'aug21',
        turningImage: Aug2021
    },
    {
        turningTitle: "September 2020",
        turningTitleClass:"color-success bg-success",
        turningLink:'sep20',
        turningImage: sept2020
    },
    {
        turningTitle: "August 2020",
        turningTitleClass: "color-warning bg-warning",
        turningLink:'aug20',
        turningImage: aug2020
    },
    {
        turningTitle: "February 2020",
        turningTitleClass: "color-danger bg-danger",
        turningLink:'feb20',
        turningImage: feb2020
    },
    {
        turningTitle: "January 2020",
        turningTitleClass: "color-primary bg-primary",
        turningLink:'jan20',
        turningImage: jan2020
    },
    {
        turningTitle: "2019",
        turningTitleClass: "color-danger bg-danger",
        turningLink:'2019',
        turningImage: t2019
    },
    {
        turningTitle: "2018",
        turningTitleClass: "color-primary bg-primary",
        turningLink:'2018',
        turningImage: t2018
    },
]

export default TurningData;