import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from "./Section4";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import robo from "../../images/robo.gif";

const Home = ({isDark}) => {
  return (<>
    
        <div>     
      <Container >
        <Section1 isDark={isDark}/>
      </Container>
      <br/><br/>
      <Section2 />
     
      <img src={robo} alt="robot" width="100%" height="inherit"/>
      
      <Container id="contact">
        <Section4 isDark={isDark}/>
        
      </Container>
      
    </div></>
  );
};

export default withRouter(Home);
